/** @jsx jsx */
import * as React from "react"
import { jsx, Box } from "theme-ui"
import { Link } from "gatsby"
import ItemTags from "./item-tags"

type BlogListItemProps = {
  post: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }
  showTags?: boolean
}

const BlogListItem = ({ post, showTags = true }: BlogListItemProps) => (
  <Box mb={4}>
    <Link to={post.slug} sx={(t) => ({ ...t.styles?.a, fontSize: [1, 2, 3], color: `text` })}>
      {post.title}
    </Link>
    <p sx={{ 
      color: `secondary`, 
      mt: 1, 
      a: { color: `secondary` }, 
      fontSize: [1, 1, 2],
      fontVariant: `small-caps`
      }}>
      <time sx={{
        backgroundColor: `rgba(95,108,128,0.1)`,
      }}>{post.date}</time>
      {post.tags && showTags && (
        <React.Fragment>
          {` • `}
          <ItemTags tags={post.tags} />
        </React.Fragment>
      )}
      {post.timeToRead && ` • `}
      {post.timeToRead && <span>{post.timeToRead} min read</span>}
    </p>
  </Box>
)

export default BlogListItem
